export function useAuth() {
  const { push } = useRouter();
  const { isProfessionalStore } = useStore();
  const { locale } = useI18n();

  const homePath = `/${locale.value}`;
  const accountPath = `/${locale.value}/account/order`;

  const successAfterLogin = async (path = '') => {
    if (path === '') {
      path = isProfessionalStore ? homePath : accountPath;
    }
    await push(path);
  };

  const successAfterLogout = async (path = '') => {
    if (path === '') {
      path = homePath;
    }
    await push(path);
    // Remove context token from cookies
    const contextToken = useCookie('sw-context-token');
    contextToken.value = null;
  };

  return {
    successAfterLogin,
    successAfterLogout,
  };
}
